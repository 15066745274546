'use client';

import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';

export default function GlobalError({ error }) {
  useEffect(() => {
    console.log('error in GlobalError', error);
    Sentry.captureException(error);
  }, [error]);

  return (
    <html>
      <body>{/* Your Error component here... */}</body>
    </html>
  );
}
// 'use client';

// import Error from 'next/error';

// export default function GlobalError({ error }) {
//   // useEffect(() => {
//   //   console.log('Error:', error);
//   //   Sentry.se(error);
//   // }, [error]);

//   return (
//     <html>
//       <body>
//         <h2>Something went wrong!</h2>
//         <Error />
//       </body>
//     </html>
//   );
// }

// 'use client';

// import * as Sentry from '@sentry/nextjs';
// import Error from 'next/error';
// import { useEffect } from 'react';

// export default function GlobalError({ error }) {
//   useEffect(() => {
//     console.log('Error:', error);
//     Sentry.captureMessage(error);
//   }, [error]);

//   return (
//     <html>
//       <body>
//         <h2>Something went wrong!</h2>
//         <Error />
//       </body>
//     </html>
//   );
// }
